import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import Dashboard from './views/dashboard/Dashboard'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const User = React.lazy(() => import('./user/user'))
const Userview = React.lazy(() => import('../src/user/userview'))
const Beta = React.lazy(() => import('./Beta/Beta'))
const Story = React.lazy(() => import('./Story/Story'))
const Blog = React.lazy(() => import('./Blog/Blog'))
const Notification = React.lazy(() => import('./Notification/Notification'))
const Createstory = React.lazy(() => import('./Story/createstory'))
const Apponint = React.lazy(() => import('./Apponint/Apponint'))
const Challenge = React.lazy(() => import('./challenge/challenge'))
const Uploadbot = React.lazy(() => import('./bot/uploadbot'))

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/user" name="User" element={<User />} />
            <Route exact path="/userview/:id" name="userview" element={<Userview />} />
            <Route exact path="/Beta" name="Beta" element={<Beta />} />
            <Route exact path="/Story" name="Story" element={<Story />} />
            <Route exact path="/createstory" name="createstory" element={<Createstory />} />
            <Route exact path="/challenge" name="challenge" element={<Challenge />} />
            <Route exact path="uploadbot" name="uploadbot" element={<Uploadbot />} />
            <Route exact path="/Blog" name="Blog" element={<Blog />} />
            <Route exact path="/Notification" name="Notification" element={<Notification />} />
            <Route exact path="/Apponint" name="Apponint" element={<Apponint />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<Login />} />
            <Route exact path="/dashboard" name="dashboard" element={<Dashboard />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
