import React, { useEffect, PureComponent, useState } from 'react'
import axios from 'axios'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRangePicker } from 'react-date-range'
import { addDays } from 'date-fns'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
} from 'recharts'
import {
  CAvatar,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CCol,
  CProgress,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import { CChartLine, CChart } from '@coreui/react-chartjs'
import { getStyle, hexToRgba } from '@coreui/utils'
import CIcon from '@coreui/icons-react'
import './dash.css'
import {
  cibCcAmex,
  cibCcApplePay,
  cibCcMastercard,
  cibCcPaypal,
  cibCcStripe,
  cibCcVisa,
  cibGoogle,
  cibFacebook,
  cibLinkedin,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cibTwitter,
  cilCloudDownload,
  cilPeople,
  cilUser,
  cilUserFemale,
} from '@coreui/icons'
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../../components/index'
import moment from 'moment/moment'
import { object } from 'prop-types'

// const labels = []
// const SingUpdata = []
const Dashboard = () => {
  const tokens = localStorage.getItem('jwtToken')
  const tokens1 = localStorage.getItem('jwtToken')
  const tokens2 = localStorage.getItem('jwtToken')
  const tokens3 = localStorage.getItem('jwtToken')
  const tokens4 = localStorage.getItem('jwtToken')
  const tokens5 = localStorage.getItem('jwtToken')
  const tokens6 = localStorage.getItem('jwtToken')
  const tokens7 = localStorage.getItem('jwtToken')
  const tokens8 = localStorage.getItem('jwtToken')
  const tokens10 = localStorage.getItem('jwtToken')
  const tokens11 = localStorage.getItem('jwtToken')
  const tokens12 = localStorage.getItem('jwtToken')
  const tokens13 = localStorage.getItem('jwtToken')
  const tokens14 = localStorage.getItem('jwtToken')
  const tokens15 = localStorage.getItem('jwtToken')

  const token = localStorage.getItem('jwtToken')
  const token1 = localStorage.getItem('jwtToken')
  const token2 = localStorage.getItem('jwtToken')
  const token3 = localStorage.getItem('jwtToken')
  const token4 = localStorage.getItem('jwtToken')
  const result0 = localStorage.getItem('jwtToken')
  const result1 = localStorage.getItem('jwtToken')
  const result2 = localStorage.getItem('jwtToken')
  const result3 = localStorage.getItem('jwtToken')
  const result4 = localStorage.getItem('jwtToken')
  const result5 = localStorage.getItem('jwtToken')
  const result6 = localStorage.getItem('jwtToken')
  const result7 = localStorage.getItem('jwtToken')
  const result8 = localStorage.getItem('jwtToken')
  const result9 = localStorage.getItem('jwtToken')
  const result = localStorage.getItem('jwtToken')
  const [label, setLabel] = useState([])
  const [data, setData] = useState([])
  const [label1, setLabel1] = useState([])
  const [gameData1, setGameData1] = useState([])
  const [gameData2, setGameData2] = useState([])
  const [gameData3, setGameData3] = useState([])
  const [label2, setLabel2] = useState([])
  const [sub1, setSub1] = useState([])
  const [sub2, setSub2] = useState([])
  const [sub3, setSub3] = useState([])
  const [sub4, setSub4] = useState([])
  const [free, setFree] = useState([])
  const [label3, setLabel3] = useState([])
  const [gameplayed, setGameplayed] = useState([])
  const [label4, setLabel4] = useState([])
  const [dailychall, setDailychall] = useState([])
  const [dailyincomplete, setDailyincomplete] = useState([])
  const [dailycomplete, setDailycomplete] = useState([])
  const [label5, setLabel5] = useState([])
  const [chall, setChall] = useState([])
  const [label6, setLabel6] = useState([])
  const [incomplete, setIncomplete] = useState([])
  const [complete, setComplete] = useState([])
  const [label7, setLabel7] = useState([])
  const [reward1, setReward1] = useState([])
  const [reward2, setReward2] = useState([])
  const [label8, setLabel8] = useState([])
  const [training1, setTraining1] = useState([])
  const [training2, setTraining2] = useState([])
  const [training3, setTraining3] = useState([])
  const [pietotal, setPietotal] = useState([])
  const [pieEng, setPieEng] = useState([])
  const [piespanish, setPiespanish] = useState([])
  const [piehindi, setPiehindi] = useState([])
  const [piechinese, setPiechinese] = useState([])
  const [piefrench, setPiefrench] = useState([])
  const [piejapnese, setPiejapnese] = useState([])
  const [pierussian, setPierussian] = useState([])
  const [piegerman, setPiegerman] = useState([])
  const [pieturkish, setPieturkish] = useState([])
  const [pieportugese, setPieportugese] = useState([])
  const [pieitalian, setPieitalian] = useState([])
  const [pieurdu, setPieurdu] = useState([])
  const [newUserolduser, setNewUserolduser] = useState([])
  const [newUseroldusers, setNewUseroldusers] = useState([])
  const [label0, setLabel0] = useState([])
  const [gameplayu, setGameplayu] = useState([])
  const [loading, setLoading] = useState(false)
  const [loading0, setLoading0] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [loading3, setLoading3] = useState(false)
  const [loading4, setLoading4] = useState(false)
  const [loading5, setLoading5] = useState(false)
  const [loading6, setLoading6] = useState(false)
  const [loading7, setLoading7] = useState(false)
  const [loading8, setLoading8] = useState(false)
  const [loading9, setLoading9] = useState(false)
  const [languagedata, setLanguagedata] = useState([])
  const [played1, setPlayed1] = useState([])
  const [labell, setLebell] = useState([])
  const [played2, setPlayed2] = useState([])

  const [loading10, setLoading10] = useState(false)
  const [label01, setLabel01] = useState([])
  const [active, setActive] = useState([])
  const [deActive, setDeActive] = useState([])
  const [loading11, setLoading11] = useState(false)
  const [label02, setLabel02] = useState([])
  const [open, setOpen] = useState([])
  const [turnOff, setTurnOff] = useState([])
  const [total, setTotal] = useState([])
  const [loading12, setLoading12] = useState([])
  const [label03, setLabel03] = useState([])
  const [totalDaily, setTotalDaily] = useState([])
  const d = new Date()
  d.setDate(d.getDate() - 7)
  const [startDate, setStartDate] = useState(d)
  const [endDate, setEndDate] = useState(new Date())

  useEffect(() => {
    setLoading0(true)
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getLanguageDistribution`, {
        headers: { 'x-custom-Token': `${result}` },
      })
      .then((res) => {
        console.log(res)
        setLanguagedata(res.data.data)

        setPietotal(res.data.data.total)
        setPieEng(res.data.data.eng)
        setPiespanish(res.data.data.spanish)
        setPiehindi(res.data.data.hindi)
        setPiechinese(res.data.data.chinese)
        setPiefrench(res.data.data.french)
        setPiejapnese(res.data.data.japnese)
        setPierussian(res.data.data.russian)
        setPiegerman(res.data.data.german)
        setPieturkish(res.data.data.turkish)
        setPieportugese(res.data.data.portugese)
        setPieitalian(res.data.data.italian)
        setPieurdu(res.data.data.urdu)
        setLoading0(false)
      })
  }, [])

  useEffect(() => {
    setLoading(true)
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getDailyActiveUsers`, {
        headers: { 'x-custom-Token': `${token1}` },
      })
      .then((res) => {
        console.log(res)
        let arry = []
        res.data.data.newUser.map((res1) => {
          arry.push({
            name: res1.date,
            newuser: res1.data,
            olduser: 2400,
            amt: 2400,
          })
        })
        res.data.data.oldUser.map((res2, index) => {
          arry[index].olduser = res2.data
        })
        setNewUserolduser(arry)
        setLoading(false)
        console.log(arry)
      })
  }, [])

  useEffect(() => {
    setLoading9(true)
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getAllGamePlayed`, {
        headers: { 'x-custom-Token': `${token2}` },
      })
      .then((res) => {
        console.log(res)
        let arry = []
        res.data.data.map((res) => {
          arry.push({
            name: res.game,
            newuser: res.newUser,
            olduser: res.oldUser,
          })
        })
        setNewUseroldusers(arry)
        console.log(arry)
        setLoading9(false)
      })
  }, [])

  useEffect(() => {
    setLoading(true)
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getDailySignUps`, {
        headers: { 'X-Custom-Token': `${token}` },
      })
      .then((res) => {
        console.log(res)
        let labelfromapi = []
        let datefromapi = []

        res.data.data.map((res) => {
          labelfromapi.push(res.date)
          datefromapi.push(res.data)
        })
        //  setData1 (res.data.data)
        setLabel(labelfromapi)
        setData(datefromapi)
        setLoading(false)
        console.log(labelfromapi)
      })
  }, [])

  // useEffect(() => {
  //   axios
  //     .get(`https://apineuronv2.monkhub.com/admin/getDailyActiveUsers`, {
  //       headers: { 'X-Custom-Token': `${result1}` },
  //     })
  //     .then((res) => {
  //       console.log(res)
  //     })
  // }, [])

  useEffect(() => {
    setLoading1(true)
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getAllGamePlayedTotal`, {
        headers: { 'x-custom-Token': `${result2}` },
      })
      .then((res) => {
        console.log(res)
        let label1fromapi = []
        let gameData1fromapi = []
        let gameData2fromapi = []
        let gameData3fromapi = []

        res.data.data.map((res) => {
          label1fromapi.push(res.date)
          gameData1fromapi.push(res.total)
          gameData2fromapi.push(res.regular)
          gameData3fromapi.push(res.streak)
        })
        setLabel1(label1fromapi)
        setGameData1(gameData1fromapi)
        setGameData2(gameData2fromapi)
        setGameData3(gameData3fromapi)
        setLoading1(false)
        console.log(label1fromapi)
      })
  }, [])

  useEffect(() => {
    setLoading6(true)
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getSubscription`, {
        headers: { 'x-custom-Token': `${result3}` },
      })
      .then((res) => {
        console.log(res)
        let label2fromapi = []
        let sub1fromapi = []
        let sub2fromapi = []
        let sub3fromapi = []
        let sub4fromapi = []
        let freefromapi = []

        res.data.data.map((res) => {
          label2fromapi.push(res.date)
          sub1fromapi.push(res.overAll)
          sub2fromapi.push(res.monthly)
          sub3fromapi.push(res.yearly)
          sub4fromapi.push(res.sixMonthly)
          freefromapi.push(res.weeklyFreeTrial)
        })
        setLabel2(label2fromapi)
        setSub1(sub1fromapi)
        setSub2(sub2fromapi)
        setSub3(sub3fromapi)
        setSub4(sub4fromapi)
        setFree(freefromapi)
        console.log(sub4fromapi)
        setLoading6(false)
      })
  }, [])

  useEffect(() => {
    setLoading3(true)
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getAllGamePlayedPerActiveUser`, {
        headers: { 'x-custom-Token': `${result4}` },
      })
      .then((res) => {
        console.log(res)
        let label3fromapi = []
        let gameplayedfromapi = []

        res.data.data.map((res) => {
          label3fromapi.push(res.date)
          gameplayedfromapi.push(res.data)
        })
        setLabel3(label3fromapi)
        setGameplayed(gameplayedfromapi)
        setLoading3(false)
      })
  }, [])

  useEffect(() => {
    setLoading4(true)
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getAllGamePlayedPerActiveUser/v2`, {
        headers: { 'x-custom-Token': `${result0}` },
      })
      .then((res) => {
        console.log(res)
        let label0fromapi = []
        let gameplayufromapi = []

        res.data.data.map((res) => {
          label0fromapi.push(res.date)
          gameplayufromapi.push(res.data)
        })
        setLabel0(label0fromapi)
        setGameplayu(gameplayufromapi)
        setLoading4(false)
      })
  }, [])

  useEffect(() => {
    setLoading5(true)
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getAllChallengesByActiveUsers`, {
        headers: { 'x-custom-Token': `${result5}` },
      })
      .then((res) => {
        console.log(res)
        let label5fromapi = []
        let challfromapi = []

        res.data.data.map((res) => {
          label5fromapi.push(res.date)
          challfromapi.push(res.data)
        })
        setLabel5(label5fromapi)
        setChall(challfromapi)
        setLoading5(false)
      })
  }, [])

  useEffect(() => {
    setLoading2(true)
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getAllChallenges`, {
        headers: { 'x-custom-Token': `${result6}` },
      })
      .then((res) => {
        console.log(res)
        let label4fromapi = []
        let dailychallfromapi = []
        let dailyincompletefromapi = []
        let dailycompletefromapi = []

        res.data.data.map((res) => {
          label4fromapi.push(res.date)
          dailychallfromapi.push(res.total)
          dailyincompletefromapi.push(res.incomplete)
          dailycompletefromapi.push(res.complete)
        })
        setLabel4(label4fromapi)
        setDailychall(dailychallfromapi)
        setDailyincomplete(dailyincompletefromapi)
        setDailycomplete(dailycompletefromapi)
        setLoading2(false)
      })
  }, [])

  useEffect(() => {
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getAllChallengesCompleteAndIncomplete`, {
        headers: { 'x-custom-Token': `${result7}` },
      })
      .then((res) => {
        console.log(res)
        let label6fromapi = []
        let incompletefromapi = []
        let completefromapi = []

        res.data.data.map((res) => {
          label6fromapi.push(res.date)
          completefromapi.push(res.complete)
          incompletefromapi.push(res.incomplete)
        })
        setLabel6(label6fromapi)
        setComplete(completefromapi)
        setIncomplete(incompletefromapi)
        console.log(completefromapi)
        console.log(incompletefromapi)
      })
  }, [])

  useEffect(() => {
    setLoading8(true)
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getDailyRewards`, {
        headers: { 'x-custom-Token': `${result8}` },
      })
      .then((res) => {
        console.log(res)
        let label7fromapi = []
        let reward1fromapi = []
        let reward2fromapi = []

        res.data.data.map((res) => {
          label7fromapi.push(res.date)
          reward1fromapi.push(res.dailyTraining)
          reward2fromapi.push(res.brainProfile)
        })
        setLabel7(label7fromapi)
        setReward1(reward1fromapi)
        setReward2(reward2fromapi)
        setLoading8(false)
      })
  }, [])

  useEffect(() => {
    setLoading7(true)
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getDailyTrainingSession`, {
        headers: { 'x-custom-Token': `${result9}` },
      })
      .then((res) => {
        console.log(res)
        let label8fromapi = []
        let training1fromapi = []
        let training2fromapi = []
        let training3fromapi = []

        res.data.data.map((res) => {
          label8fromapi.push(res.date)
          training1fromapi.push(res.newUser)
          training2fromapi.push(res.returningUser)
          training3fromapi.push(res.total)
        })
        setLabel8(label8fromapi)
        setTraining1(training1fromapi)
        setTraining2(training2fromapi)
        setTraining3(training3fromapi)
        setLoading7(false)
      })
  }, [])

  useEffect(() => {
    setLoading10(true)
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getActiveDailyReminders`, {
        headers: { 'X-Custom-Token': `${result1}` },
      })
      .then((res) => {
        console.log(res)
        let label01fromapi = []
        let activefromapi = []
        let deActivefromapi = []

        res.data.data.map((res) => {
          label01fromapi.push(res.date)
          activefromapi.push(res.data.active)
          deActivefromapi.push(res.data.deActivate)
        })

        setLabel01(label01fromapi)
        setActive(activefromapi)
        setDeActive(deActivefromapi)
        console.log(activefromapi, deActivefromapi)
        setLoading10(false)
        console.log(label01)
      })
  }, [])

  useEffect(() => {
    setLoading11(true)
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getDailyRemindersClicks`, {
        headers: { 'X-Custom-Token': `${token3}` },
      })
      .then((res) => {
        console.log(res)
        let label02fromapi = []
        let openfromapi = []
        let turnOfffromapi = []
        let totalfromapi = []

        res.data.data.map((res) => {
          label02fromapi.push(res.date)
          openfromapi.push(res.data.open)
          turnOfffromapi.push(res.data.turnOff)
          totalfromapi.push(res.data.total)
        })

        setLabel02(label02fromapi)
        setTurnOff(turnOfffromapi)
        setOpen(openfromapi)
        setTotal(totalfromapi)
        console.log(totalfromapi)
        setLoading11(false)
      })
  }, [])

  useEffect(() => {
    setLoading12(true)
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getTotalDailyReminders`, {
        headers: { 'X-Custom-Token': `${token4}` },
      })
      .then((res) => {
        console.log(res)
        let label03fromapi = []
        let totalDailyfromapi = []

        res.data.data.map((res) => {
          label03fromapi.push(res.date)
          totalDailyfromapi.push(res.data)
        })

        setLabel03(label03fromapi)
        setTotalDaily(totalDailyfromapi)
        setLoading12(false)
      })
  }, [])

  const handelsubmit = () => {
    // console.log("ab")
    setLoading(true)
    let sdate = moment(startDate).format('L').split('/')
    let edate = moment(endDate).format('L').split('/')
    sdate = sdate[2] + '-' + sdate[0] + '-' + sdate[1]
    edate = edate[2] + '-' + edate[0] + '-' + edate[1]
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getDailySignUps/${sdate}/${edate}`, {
        headers: { 'x-custom-Token': `${tokens}` },
      })
      .then((res) => {
        console.log(res)
        let labelfromapi = []
        let datefromapi = []

        res.data.data.map((res) => {
          labelfromapi.push(res.date)
          datefromapi.push(res.data)
        })
        //  setData1 (res.data.data)
        setLabel(labelfromapi)
        setData(datefromapi)
        setLoading(false)
        console.log(labelfromapi)
      })
  }

  const handelsubmit1 = () => {
    setLoading(true)
    let sdate = moment(startDate).format('L').split('/')
    let edate = moment(endDate).format('L').split('/')
    sdate = sdate[2] + '-' + sdate[0] + '-' + sdate[1]
    edate = edate[2] + '-' + edate[0] + '-' + edate[1]
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getDailyActiveUsers/${sdate}/${edate}`, {
        headers: { 'x-custom-Token': `${tokens1}` },
      })
      .then((res) => {
        console.log(res)
        let arry = []
        res.data.data.newUser.map((res1) => {
          arry.push({
            name: res1.date,
            newuser: res1.data,
            olduser: 2400,
            amt: 2400,
          })
        })
        res.data.data.oldUser.map((res2, index) => {
          arry[index].olduser = res2.data
        })
        setNewUserolduser(arry)
        setLoading(false)
        console.log(arry)
      })
  }

  const handelsubmit2 = () => {
    setLoading1(true)
    let sdate = moment(startDate).format('L').split('/')
    let edate = moment(endDate).format('L').split('/')
    sdate = sdate[2] + '-' + sdate[0] + '-' + sdate[1]
    edate = edate[2] + '-' + edate[0] + '-' + edate[1]
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getAllGamePlayedTotal/${sdate}/${edate}`, {
        headers: { 'x-custom-Token': `${tokens2}` },
      })
      .then((res) => {
        console.log(res)
        let label1fromapi = []
        let gameData1fromapi = []
        let gameData2fromapi = []
        let gameData3fromapi = []

        res.data.data.map((res) => {
          label1fromapi.push(res.date)
          gameData1fromapi.push(res.total)
          gameData2fromapi.push(res.regular)
          gameData3fromapi.push(res.streak)
        })
        setLabel1(label1fromapi)
        setGameData1(gameData1fromapi)
        setGameData2(gameData2fromapi)
        setGameData3(gameData3fromapi)
        setLoading1(false)
        console.log(label1fromapi)
      })
  }

  const handelDaily = (e) => {
    setLoading9(true)

    axios
      .get(`https://apineuronv2.monkhub.com/admin/getAllGamePlayedByDate/1`, {
        headers: { 'x-custom-Token': `${tokens12}` },
      })
      .then((res) => {
        console.log(res)
        let arry = []
        res.data.data.map((res) => {
          arry.push({
            name: res.game,
            newuser: res.newUser,
            olduser: res.oldUser,
          })
        })
        setNewUseroldusers(arry)
        console.log(arry)
        setLoading9(false)
      })

    // res.data.data.map()
  }
  const handelWeekly = (e) => {
    setLoading9(true)

    axios
      .get(`https://apineuronv2.monkhub.com/admin/getAllGamePlayedByDate/7`, {
        headers: { 'x-custom-Token': `${tokens12}` },
      })
      .then((res) => {
        console.log(res)
        let arry = []
        res.data.data.map((res) => {
          arry.push({
            name: res.game,
            newuser: res.newUser,
            olduser: res.oldUser,
          })
        })
        setNewUseroldusers(arry)
        console.log(arry)
        setLoading9(false)
      })

    // res.data.data.map()
  }
  const handelMonthly = (e) => {
    setLoading9(true)

    axios
      .get(`https://apineuronv2.monkhub.com/admin/getAllGamePlayedByDate/30`, {
        headers: { 'x-custom-Token': `${tokens12}` },
      })
      .then((res) => {
        console.log(res)
        let arry = []
        res.data.data.map((res) => {
          arry.push({
            name: res.game,
            newuser: res.newUser,
            olduser: res.oldUser,
          })
        })
        setNewUseroldusers(arry)
        console.log(arry)
        setLoading9(false)
      })

    // res.data.data.map()
  }

  const handelsubmit4 = () => {
    setLoading6(true)
    let sdate = moment(startDate).format('L').split('/')
    let edate = moment(endDate).format('L').split('/')
    sdate = sdate[2] + '-' + sdate[0] + '-' + sdate[1]
    edate = edate[2] + '-' + edate[0] + '-' + edate[1]
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getSubscription/${sdate}/${edate}`, {
        headers: { 'x-custom-Token': `${tokens4}` },
      })
      .then((res) => {
        console.log(res)
        let label2fromapi = []
        let sub1fromapi = []
        let sub2fromapi = []
        let sub3fromapi = []
        let sub4fromapi = []
        let freefromapi = []

        res.data.data.map((res) => {
          label2fromapi.push(res.date)
          sub1fromapi.push(res.overAll)
          sub2fromapi.push(res.monthly)
          sub3fromapi.push(res.yearly)
          sub4fromapi.push(res.sixMonthly)
          freefromapi.push(res.weeklyFreeTrial)
        })
        setLabel2(label2fromapi)
        setSub1(sub1fromapi)
        setSub2(sub2fromapi)
        setSub3(sub3fromapi)
        setSub4(sub4fromapi)
        setFree(freefromapi)
        console.log(sub4fromapi)
        setLoading6(false)
      })
  }

  const handelsubmit5 = () => {
    setLoading7(true)
    let sdate = moment(startDate).format('L').split('/')
    let edate = moment(endDate).format('L').split('/')
    sdate = sdate[2] + '-' + sdate[0] + '-' + sdate[1]
    edate = edate[2] + '-' + edate[0] + '-' + edate[1]
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getDailyTrainingSession/${sdate}/${edate}`, {
        headers: { 'x-custom-Token': `${tokens5}` },
      })
      .then((res) => {
        console.log(res)
        let label8fromapi = []
        let training1fromapi = []
        let training2fromapi = []
        let training3fromapi = []

        res.data.data.map((res) => {
          label8fromapi.push(res.date)
          training1fromapi.push(res.newUser)
          training2fromapi.push(res.returningUser)
          training3fromapi.push(res.total)
        })
        setLabel8(label8fromapi)
        setTraining1(training1fromapi)
        setTraining2(training2fromapi)
        setTraining3(training3fromapi)
        setLoading7(false)
      })
  }

  const handelsubmit6 = () => {
    setLoading8(true)
    let sdate = moment(startDate).format('L').split('/')
    let edate = moment(endDate).format('L').split('/')
    sdate = sdate[2] + '-' + sdate[0] + '-' + sdate[1]
    edate = edate[2] + '-' + edate[0] + '-' + edate[1]
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getDailyRewards/${sdate}/${edate}`, {
        headers: { 'x-custom-Token': `${tokens6}` },
      })
      .then((res) => {
        console.log(res)
        let label7fromapi = []
        let reward1fromapi = []
        let reward2fromapi = []

        res.data.data.map((res) => {
          label7fromapi.push(res.date)
          reward1fromapi.push(res.dailyTraining)
          reward2fromapi.push(res.brainProfile)
        })
        setLabel7(label7fromapi)
        setReward1(reward1fromapi)
        setReward2(reward2fromapi)
        setLoading8(false)
      })
  }

  const handelsubmit7 = () => {
    setLoading3(true)
    let sdate = moment(startDate).format('L').split('/')
    let edate = moment(endDate).format('L').split('/')
    sdate = sdate[2] + '-' + sdate[0] + '-' + sdate[1]
    edate = edate[2] + '-' + edate[0] + '-' + edate[1]
    axios
      .get(
        `https://apineuronv2.monkhub.com/admin/getAllGamePlayedPerActiveUser/${sdate}/${edate}`,
        {
          headers: { 'x-custom-Token': `${tokens7}` },
        },
      )

      .then((res) => {
        console.log(res)
        let label3fromapi = []
        let gameplayedfromapi = []

        res.data.data.map((res) => {
          label3fromapi.push(res.date)
          gameplayedfromapi.push(res.data)
        })
        setLabel3(label3fromapi)
        setGameplayed(gameplayedfromapi)
        setLoading3(false)
      })
  }

  const handelsubmit8 = () => {
    setLoading4(true)
    let sdate = moment(startDate).format('L').split('/')
    let edate = moment(endDate).format('L').split('/')
    sdate = sdate[2] + '-' + sdate[0] + '-' + sdate[1]
    edate = edate[2] + '-' + edate[0] + '-' + edate[1]
    axios
      .get(
        `https://apineuronv2.monkhub.com/admin/getAllGamePlayedPerActiveUser/v2/${sdate}/${edate}`,
        {
          headers: { 'x-custom-Token': `${tokens8}` },
        },
      )
      .then((res) => {
        console.log(res)
        let label0fromapi = []
        let gameplayufromapi = []

        res.data.data.map((res) => {
          label0fromapi.push(res.date)
          gameplayufromapi.push(res.data)
        })
        setLabel0(label0fromapi)
        setGameplayu(gameplayufromapi)
        setLoading4(false)
      })
  }

  const handelsubmit10 = () => {
    setLoading2(true)
    let sdate = moment(startDate).format('L').split('/')
    let edate = moment(endDate).format('L').split('/')
    sdate = sdate[2] + '-' + sdate[0] + '-' + sdate[1]
    edate = edate[2] + '-' + edate[0] + '-' + edate[1]
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getAllChallenges/${sdate}/${edate}`, {
        headers: { 'x-custom-Token': `${tokens10}` },
      })
      .then((res) => {
        console.log(res)
        let label4fromapi = []
        let dailychallfromapi = []
        let dailyincompletefromapi = []
        let dailycompletefromapi = []

        res.data.data.map((res) => {
          label4fromapi.push(res.date)
          dailychallfromapi.push(res.total)
          dailyincompletefromapi.push(res.incomplete)
          dailycompletefromapi.push(res.complete)
        })
        setLabel4(label4fromapi)
        setDailychall(dailychallfromapi)
        setDailyincomplete(dailyincompletefromapi)
        setDailycomplete(dailycompletefromapi)
        setLoading2(false)
      })
  }

  const handelsubmit11 = () => {
    setLoading5(true)
    let sdate = moment(startDate).format('L').split('/')
    let edate = moment(endDate).format('L').split('/')
    sdate = sdate[2] + '-' + sdate[0] + '-' + sdate[1]
    edate = edate[2] + '-' + edate[0] + '-' + edate[1]
    axios
      .get(
        `https://apineuronv2.monkhub.com/admin/getAllChallengesByActiveUsers/${sdate}/${edate}`,
        {
          headers: { 'x-custom-Token': `${tokens11}` },
        },
      )
      .then((res) => {
        console.log(res)
        let label5fromapi = []
        let challfromapi = []

        res.data.data.map((res) => {
          label5fromapi.push(res.date)
          challfromapi.push(res.data)
        })
        setLabel5(label5fromapi)
        setChall(challfromapi)
        setLoading5(false)
      })
  }

  const handelsubmit12 = () => {
    setLoading(true)
    let sdate = moment(startDate).format('L').split('/')
    let edate = moment(endDate).format('L').split('/')
    sdate = sdate[2] + '-' + sdate[0] + '-' + sdate[1]
    edate = edate[2] + '-' + edate[0] + '-' + edate[1]
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getActiveDailyReminders/${sdate}/${edate}`, {
        headers: { 'X-Custom-Token': `${tokens13}` },
      })
      .then((res) => {
        console.log(res)
        let label01fromapi = []
        let activefromapi = []
        let deActivefromapi = []

        res.data.data.map((res) => {
          label01fromapi.push(res.date)
          activefromapi.push(res.data.active)
          deActivefromapi.push(res.data.deActivate)
        })

        setLabel01(label01fromapi)
        setActive(activefromapi)
        setDeActive(deActivefromapi)
        console.log(activefromapi, deActivefromapi)
        setLoading10(false)
        console.log(label01)
      })
  }
  const handelsubmit13 = () => {
    setLoading(true)
    let sdate = moment(startDate).format('L').split('/')
    let edate = moment(endDate).format('L').split('/')
    sdate = sdate[2] + '-' + sdate[0] + '-' + sdate[1]
    edate = edate[2] + '-' + edate[0] + '-' + edate[1]
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getDailyRemindersClicks/${sdate}/${edate}`, {
        headers: { 'X-Custom-Token': `${tokens14}` },
      })
      .then((res) => {
        console.log(res)
        let label02fromapi = []
        let openfromapi = []
        let turnOfffromapi = []
        let totalfromapi = []

        res.data.data.map((res) => {
          label02fromapi.push(res.date)
          openfromapi.push(res.data.open)
          turnOfffromapi.push(res.data.turnOff)
          totalfromapi.push(res.data.turnOff)
        })

        setLabel02(label02fromapi)
        setTurnOff(turnOfffromapi)
        setOpen(openfromapi)
        setTotal(totalfromapi)
        setLoading11(false)
        console.log(totalfromapi)
      })
  }

  const handelsubmit14 = () => {
    setLoading(true)
    let sdate = moment(startDate).format('L').split('/')
    let edate = moment(endDate).format('L').split('/')
    sdate = sdate[2] + '-' + sdate[0] + '-' + sdate[1]
    edate = edate[2] + '-' + edate[0] + '-' + edate[1]
    axios
      .get(`https://apineuronv2.monkhub.com/admin/getTotalDailyReminders/${sdate}/${edate}`, {
        headers: { 'X-Custom-Token': `${token4}` },
      })
      .then((res) => {
        console.log(res)
        let label03fromapi = []
        let totalDailyfromapi = []

        res.data.data.map((res) => {
          label03fromapi.push(res.date)
          totalDailyfromapi.push(res.data)
        })

        setLabel03(label03fromapi)
        setTotalDaily(totalDailyfromapi)
        setLoading12(false)
      })
  }

  return (
    <>
      <div>
        <AppSidebar />
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <AppHeader />
          {/* <div className="body flex-grow-1 px-3">
            <WidgetsDropdown />
          </div> */}

          <div>
            <CCard className=" m-4">
              <CCardHeader>
                <div
                  className="d-flex flex-row justify-content-between "
                  style={{ margin: '0 auto', marginRight: '9px' }}
                >
                  <div>
                    <strong>Daily singup&apos;s</strong>
                  </div>
                </div>
              </CCardHeader>
              <CCardHeader>
                <div className="d-flex justify-content-end">
                  <div>
                    <label>Start Date</label>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                  </div>
                  <div style={{ marginLeft: '-65px', marginRight: '-50px' }}>
                    <label>End Date</label>
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                  </div>
                  <div className="mt-4" style={{ marginLeft: '0px' }}>
                    <CButton
                      onClick={() => {
                        handelsubmit()
                      }}
                      size="sm"
                      style={{ height: '30px', marginTop: '0px' }}
                    >
                      Submit
                    </CButton>
                  </div>
                </div>
              </CCardHeader>

              <CCardBody>
                {loading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '80vh' }}
                  >
                    <div className="spinner-border m-5" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <CChartLine
                    className="singupchart"
                    data={{
                      labels: label,
                      datasets: [
                        {
                          label: 'Daily Singup',
                          // backgroundColor: 'rgba(151, 187, 205, 0.2)',
                          borderColor: '#292D30',
                          pointBackgroundColor: '#292D30',
                          pointBorderColor: '3px solid #292D30',
                          data: data,
                        },
                      ],
                    }}
                  />
                )}
              </CCardBody>
            </CCard>
          </div>
          <div>
            <CCard className="m-4">
              <CCardHeader>
                <strong>Daily Active Users</strong>
              </CCardHeader>
              <CCardHeader>
                <div className="d-flex justify-content-end">
                  <div>
                    <label>Start Date</label>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                  </div>
                  <div style={{ marginLeft: '-65px', marginRight: '-50px' }}>
                    <label>End Date</label>
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                  </div>
                  <div className="mt-4" style={{ marginLeft: '0px' }}>
                    <CButton
                      onClick={() => {
                        handelsubmit1()
                      }}
                      size="sm"
                      style={{ height: '30px', marginTop: '0px' }}
                    >
                      Submit
                    </CButton>
                  </div>
                </div>
              </CCardHeader>
              <CCardBody>
                {loading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '80vh' }}
                  >
                    <div className="spinner-border m-5" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <ResponsiveContainer width="100%" aspect={3}>
                    <BarChart
                      width={500}
                      height={300}
                      data={newUserolduser}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="newuser" stackId="a" fill="#8884d8" />
                      <Bar dataKey="olduser" stackId="a" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </CCardBody>
            </CCard>
          </div>
          <div>
            <CCard className=" m-4">
              <CCardHeader>
                <strong>Games Played</strong>
              </CCardHeader>
              <CCardHeader>
                <div className="d-flex justify-content-end">
                  <div>
                    <label>Start Date</label>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                  </div>
                  <div style={{ marginLeft: '-65px', marginRight: '-50px' }}>
                    <label>End Date</label>
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                  </div>
                  <div className="mt-4" style={{ marginLeft: '0px' }}>
                    <CButton
                      onClick={() => {
                        handelsubmit2()
                      }}
                      size="sm"
                      style={{ height: '30px', marginTop: '0px' }}
                    >
                      Submit
                    </CButton>
                  </div>
                </div>
              </CCardHeader>
              <CCardBody>
                {loading1 ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '80vh' }}
                  >
                    <div className="spinner-border m-5" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <CChartLine
                    className="singupchart"
                    data={{
                      labels: label1,
                      datasets: [
                        {
                          label: 'Total',
                          backgroundColor: '#292D30',
                          borderColor: '#292D30',
                          pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                          pointBorderColor: '3px solid #292D30',
                          data: gameData1,
                        },
                        {
                          label: 'Regular Games',
                          backgroundColor: '#8884d8',
                          borderColor: '#8884d8',
                          pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                          pointBorderColor: '3px solid #8884d8',
                          data: gameData2,
                        },
                        {
                          label: 'Streak Games',
                          backgroundColor: 'rgba(151, 187, 205, 0.2)',
                          borderColor: '#04DC9B',
                          pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                          pointBorderColor: ' 3px solid #04DC9B',
                          data: gameData3,
                        },
                      ],
                    }}
                  />
                )}
              </CCardBody>
            </CCard>
          </div>
          <div>
            <CCard className="m-4">
              <CCardHeader>
                <strong>Games Played</strong>
              </CCardHeader>
              <CCardHeader>
                <div className="d-flex flex-row justify-content-end ">
                  <div className="d-flex flex-row ">
                    <CCol sm={7} className="d-none d-md-block">
                      <CButtonGroup
                        className=""
                        style={{ marginLeft: '105px', marginBottom: '20px' }}
                      >
                        <CButton
                          color="outline-secondary"
                          // key={value}
                          className="mx-0"
                          // active={value === 'Month'}
                          onClick={(e) => handelDaily(e)}
                        >
                          Daily
                        </CButton>
                        <CButton
                          color="outline-secondary"
                          className="mx-0"
                          onClick={(e) => handelWeekly(e)}
                        >
                          weekly
                        </CButton>
                        <CButton
                          color="outline-secondary"
                          // key={value}
                          className="mx-0"
                          onClick={(e) => handelMonthly(e)}
                        >
                          monthly
                        </CButton>
                      </CButtonGroup>
                    </CCol>
                  </div>
                </div>
              </CCardHeader>
              <CCardBody>
                {loading9 ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '80vh' }}
                  >
                    <div className="spinner-border m-5" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <ResponsiveContainer width="100%" aspect={3} align="middle" hover responsive>
                    <BarChart
                      width={500}
                      height={300}
                      data={newUseroldusers}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="5 5" />
                      <XAxis dataKey="name" fontSize={'6px'} angle={12} minTickGap={1} />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="newuser" stackId="a" fill="#8884d8" barSize={30} />
                      <Bar dataKey="olduser" stackId="a" fill="#82ca9d" barSize={30} />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </CCardBody>
            </CCard>
          </div>

          <div>
            <CCard className=" m-4">
              <CCardHeader>
                <strong>Subscription</strong>
              </CCardHeader>
              <CCardHeader>
                <div className="d-flex justify-content-end">
                  <div>
                    <label>Start Date</label>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                  </div>
                  <div style={{ marginLeft: '-65px', marginRight: '-50px' }}>
                    <label>End Date</label>
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                  </div>
                  <div className="mt-4" style={{ marginLeft: '0px' }}>
                    <CButton
                      onClick={() => {
                        handelsubmit4()
                      }}
                      size="sm"
                      style={{ height: '30px', marginTop: '0px' }}
                    >
                      Submit
                    </CButton>
                  </div>
                </div>
              </CCardHeader>
              <CCardBody>
                {loading6 ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '80vh' }}
                  >
                    <div className="spinner-border m-5" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <CChartLine
                    className="singupchart"
                    data={{
                      labels: label2,
                      datasets: [
                        {
                          label: 'Overall',
                          backgroundColor: 'rgba(151, 187, 205, 0.2)',
                          borderColor: '#292D30',
                          pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                          pointBorderColor: '3px solid #292D30',
                          data: sub1,
                        },
                        {
                          label: 'monthly',
                          backgroundColor: 'rgba(151, 187, 205, 0.2)',
                          borderColor: '#F15F9D',
                          pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                          pointBorderColor: '3px solid #F15F9D',
                          data: sub2,
                        },
                        {
                          label: '6 Monthly',
                          backgroundColor: 'rgba(151, 187, 205, 0.2)',
                          borderColor: '#956ABD',
                          pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                          pointBorderColor: ' 3px solid #956ABD',
                          data: sub3,
                        },
                        {
                          label: 'yearly',
                          backgroundColor: 'rgba(151, 187, 205, 0.2)',
                          borderColor: '#FFD533',
                          pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                          pointBorderColor: ' 3px solid #956ABD',
                          data: sub4,
                        },
                        {
                          label: 'Free Trail',
                          backgroundColor: 'rgba(151, 187, 205, 0.2)',
                          borderColor: '#ED0202',
                          pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                          pointBorderColor: '3px solid #ED0202',
                          data: free,
                        },
                      ],
                    }}
                  />
                )}
              </CCardBody>
            </CCard>
          </div>
          <div>
            <CCard className=" m-4">
              <CCardHeader>
                <strong>Daily Training Session Completed (New Users Vs Returning Users)</strong>
              </CCardHeader>
              <CCardHeader>
                <div className="d-flex justify-content-end">
                  <div>
                    <label>Start Date</label>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                  </div>
                  <div style={{ marginLeft: '-65px', marginRight: '-50px' }}>
                    <label>End Date</label>
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                  </div>
                  <div className="mt-4" style={{ marginLeft: '0px' }}>
                    <CButton
                      onClick={() => {
                        handelsubmit5()
                      }}
                      size="sm"
                      style={{ height: '30px', marginTop: '0px' }}
                    >
                      Submit
                    </CButton>
                  </div>
                </div>
              </CCardHeader>
              <CCardBody>
                {loading7 ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '80vh' }}
                  >
                    <div className="spinner-border m-5" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <CChartLine
                    className="singupchart"
                    data={{
                      labels: label8,
                      datasets: [
                        {
                          label: 'Total',
                          backgroundColor: 'rgba(151, 187, 205, 0.2)',
                          borderColor: '#292D30',
                          pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                          pointBorderColor: '3px solid #292D30',
                          data: training3,
                        },
                        {
                          label: 'New',
                          backgroundColor: 'rgba(151, 187, 205, 0.2)',
                          borderColor: '#8884d8',
                          pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                          pointBorderColor: '3px solid #8884d8',
                          data: training1,
                        },
                        {
                          label: 'Returning',
                          backgroundColor: 'rgba(151, 187, 205, 0.2)',
                          borderColor: '#82ca9d',
                          pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                          pointBorderColor: ' 3px solid #82ca9d',
                          data: training2,
                        },
                      ],
                    }}
                  />
                )}
              </CCardBody>
            </CCard>
          </div>
          <div>
            <CCard className=" m-4">
              <CCardHeader>
                <strong>Rewards Ad</strong>
              </CCardHeader>
              <CCardHeader>
                <div className="d-flex justify-content-end">
                  <div>
                    <label>Start Date</label>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                  </div>
                  <div style={{ marginLeft: '-65px', marginRight: '-50px' }}>
                    <label>End Date</label>
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                  </div>
                  <div className="mt-4" style={{ marginLeft: '0px' }}>
                    <CButton
                      onClick={() => {
                        handelsubmit6()
                      }}
                      size="sm"
                      style={{ height: '30px', marginTop: '0px' }}
                    >
                      Submit
                    </CButton>
                  </div>
                </div>
              </CCardHeader>
              <CCardBody>
                {loading8 ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '80vh' }}
                  >
                    <div className="spinner-border m-5" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <CChartLine
                    className="singupchart"
                    data={{
                      labels: label7,
                      datasets: [
                        {
                          label: 'Daily Training Game Reward',
                          backgroundColor: 'rgba(151, 187, 205, 0.2)',
                          borderColor: '#ED0202',
                          pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                          pointBorderColor: '3px solid #ED0202',
                          data: reward1,
                        },
                        {
                          label: 'Brain Profile',
                          backgroundColor: 'rgba(151, 187, 205, 0.2)',
                          borderColor: '#0288D1',
                          pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                          pointBorderColor: '3px solid #0288D1',
                          data: reward2,
                        },
                      ],
                    }}
                  />
                )}
              </CCardBody>
            </CCard>
          </div>

          <div>
            <CCard className=" m-4">
              <CCardHeader>
                <strong>Total Games Played </strong>
              </CCardHeader>
              <CCardHeader>
                <div className="d-flex justify-content-end">
                  <div>
                    <label>Start Date</label>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                  </div>
                  <div style={{ marginLeft: '-65px', marginRight: '-50px' }}>
                    <label>End Date</label>
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                  </div>
                  <div className="mt-4" style={{ marginLeft: '0px' }}>
                    <CButton
                      onClick={() => {
                        handelsubmit7()
                      }}
                      size="sm"
                      style={{ height: '30px', marginTop: '0px' }}
                    >
                      Submit
                    </CButton>
                  </div>
                </div>
              </CCardHeader>
              <CCardBody>
                {loading3 ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '80vh' }}
                  >
                    <div className="spinner-border m-5" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <CChartLine
                    className="singupchart"
                    data={{
                      labels: label3,
                      datasets: [
                        {
                          label: 'Total Game Played',
                          backgroundColor: 'rgba(151, 187, 205, 0.2)',
                          borderColor: '#292D30',
                          pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                          pointBorderColor: '3px solid #292D30',
                          data: gameplayed,
                        },
                      ],
                    }}
                  />
                )}
              </CCardBody>
            </CCard>
          </div>
          <div>
            <CCard className=" m-4">
              <CCardHeader>
                <strong>Games Played per active user</strong>
              </CCardHeader>
              <CCardHeader>
                <div className="d-flex justify-content-end">
                  <div>
                    <label>Start Date</label>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                  </div>
                  <div style={{ marginLeft: '-65px', marginRight: '-50px' }}>
                    <label>End Date</label>
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                  </div>
                  <div className="mt-4" style={{ marginLeft: '0px' }}>
                    <CButton
                      onClick={() => {
                        handelsubmit8()
                      }}
                      size="sm"
                      style={{ height: '30px', marginTop: '0px' }}
                    >
                      Submit
                    </CButton>
                  </div>
                </div>
              </CCardHeader>
              <CCardBody>
                {loading4 ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '80vh' }}
                  >
                    <div className="spinner-border m-5" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <CChartLine
                    className="singupchart"
                    data={{
                      labels: label0,
                      datasets: [
                        {
                          label: 'Total Game Played per active user',
                          backgroundColor: 'rgba(151, 187, 205, 0.2)',
                          borderColor: '#292D30',
                          pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                          pointBorderColor: '3px solid #292D30',
                          data: gameplayu,
                        },
                      ],
                    }}
                  />
                )}
              </CCardBody>
            </CCard>
          </div>

          <div>
            <CCard className=" m-4">
              <CCardHeader>Language Distribution</CCardHeader>
              <div className="row">
                <div className="col-md-3">
                  <CTable striped className="table m-3" align="middle" hover>
                    <CTableHead className="table-dark ">
                      <CTableRow>
                        <CTableHeaderCell scope="col">Language</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Data</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {Object.keys(languagedata).map((res, index) => {
                        return (
                          <>
                            {Object.values(languagedata).map((value, indexvalue) => {
                              return (
                                <>
                                  {index === indexvalue ? (
                                    <>
                                      {
                                        <CTableRow v-for="item in tableItems">
                                          <CTableDataCell>{res}</CTableDataCell>
                                          <CTableDataCell>{value}%</CTableDataCell>
                                        </CTableRow>
                                      }
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </>
                              )
                            })}
                          </>
                        )
                      })}
                    </CTableBody>
                  </CTable>
                </div>
                <div className="col-md-2">
                  <CCardBody>
                    <div className="d-flex">
                      <div></div>
                      <div style={{ marginLeft: '300px' }}>
                        <CChart
                          className="singupchart1"
                          type="pie"
                          data={{
                            labels: [
                              'Eng',
                              'spanish',
                              'hindi',
                              'chinese',
                              'french',
                              'japnese',
                              'russian',
                              'german',
                              ' turkish',
                              'portugese',
                              'italian',
                              'urdu',
                            ],
                            datasets: [
                              {
                                backgroundColor: [
                                  '#B469FF',
                                  '#FFED62',
                                  '#04DC9B',
                                  '#FF59B2',
                                  '#FF884D',
                                  '#0288D1',
                                  '#9a0e49',
                                  '#4b2d66',
                                  '#ED0202',
                                  '#facade',
                                ],
                                data: [
                                  pieEng,
                                  piespanish,
                                  piehindi,
                                  piechinese,
                                  piefrench,
                                  piejapnese,
                                  pierussian,
                                  piegerman,
                                  pieturkish,
                                  pieportugese,
                                  pieitalian,
                                  pieurdu,
                                ],
                              },
                            ],
                          }}
                        />
                      </div>
                    </div>
                  </CCardBody>
                </div>
              </div>
            </CCard>
          </div>

          <div>
            <CCard className=" m-4">
              <CCardHeader>Daily Challenges</CCardHeader>
              <CCardHeader>
                <div className="d-flex justify-content-end">
                  <div>
                    <label>Start Date</label>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                  </div>
                  <div style={{ marginLeft: '-65px', marginRight: '-50px' }}>
                    <label>End Date</label>
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                  </div>
                  <div className="mt-4" style={{ marginLeft: '0px' }}>
                    <CButton
                      onClick={() => {
                        handelsubmit10()
                      }}
                      size="sm"
                      style={{ height: '30px', marginTop: '0px' }}
                    >
                      Submit
                    </CButton>
                  </div>
                </div>
              </CCardHeader>
              <CCardBody>
                {loading2 ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '80vh' }}
                  >
                    <div className="spinner-border m-5" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <CChartLine
                    className="singupchart"
                    data={{
                      labels: label4,
                      datasets: [
                        {
                          label: 'Total',
                          backgroundColor: '#292D30',
                          borderColor: '#292D30',
                          pointBackgroundColor: '#292D30',
                          pointBorderColor: '3px solid #292D30',
                          data: dailychall,
                        },
                        {
                          label: 'complete',
                          backgroundColor: '#8884d8',
                          borderColor: '#8884d8',
                          pointBackgroundColor: '#8884d8',
                          pointBorderColor: '3px solid #8884d8',
                          data: dailycomplete,
                        },
                        {
                          label: 'incomplete',
                          backgroundColor: '#82ca9d',
                          borderColor: '#82ca9d',
                          pointBackgroundColor: '#82ca9d',
                          pointBorderColor: '3px solid #82ca9d',
                          data: dailyincomplete,
                        },
                      ],
                    }}
                  />
                )}
              </CCardBody>
            </CCard>
          </div>
          <div>
            <CCard className=" m-4">
              <CCardHeader>Challenges / daily active users</CCardHeader>
              <CCardHeader>
                <div className="d-flex justify-content-end">
                  <div>
                    <label>Start Date</label>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                  </div>
                  <div style={{ marginLeft: '-65px', marginRight: '-50px' }}>
                    <label>End Date</label>
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                  </div>
                  <div className="mt-4" style={{ marginLeft: '0px' }}>
                    <CButton
                      onClick={() => {
                        handelsubmit11()
                      }}
                      size="sm"
                      style={{ height: '30px', marginTop: '0px' }}
                    >
                      Submit
                    </CButton>
                  </div>
                </div>
              </CCardHeader>
              <CCardBody>
                {loading5 ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '80vh' }}
                  >
                    <div className="spinner-border m-5" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <CChartLine
                    className="singupchart"
                    data={{
                      labels: label5,
                      datasets: [
                        {
                          label: 'Challenges / daily active users',
                          backgroundColor: '#292D30',
                          borderColor: '#292D30',
                          pointBackgroundColor: '#292D30',
                          pointBorderColor: '3px solid #292D30',
                          data: chall,
                        },
                      ],
                    }}
                  />
                )}
              </CCardBody>
            </CCard>
          </div>
          <div>
            <CCard className=" m-4">
              <CCardHeader>Active Daily Reminder</CCardHeader>
              <CCardHeader>
                <div className="d-flex justify-content-end">
                  <div>
                    <label>Start Date</label>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                  </div>
                  <div style={{ marginLeft: '-65px', marginRight: '-50px' }}>
                    <label>End Date</label>
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                  </div>
                  <div className="mt-4" style={{ marginLeft: '0px' }}>
                    <CButton
                      onClick={() => {
                        handelsubmit12()
                      }}
                      size="sm"
                      style={{ height: '30px', marginTop: '0px' }}
                    >
                      Submit
                    </CButton>
                  </div>
                </div>
              </CCardHeader>
              <CCardBody>
                {loading10 ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '80vh' }}
                  >
                    <div className="spinner-border m-5" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <CChartLine
                    className="singupchart"
                    data={{
                      labels: label01,
                      datasets: [
                        {
                          label: 'Active',
                          backgroundColor: '#8884d8',
                          borderColor: '#8884d8',
                          pointBackgroundColor: '#8884d8',
                          pointBorderColor: '3px solid #8884d8',
                          data: active,
                        },
                        {
                          label: 'Deactive',
                          backgroundColor: '#82ca9d',
                          borderColor: '#82ca9d',
                          pointBackgroundColor: '#82ca9d',
                          pointBorderColor: '3px solid #82ca9d',
                          data: deActive,
                        },
                      ],
                    }}
                  />
                )}
              </CCardBody>
            </CCard>
          </div>
          <div>
            <CCard className=" m-4">
              <CCardHeader> Daily Reminder Clicks</CCardHeader>
              <CCardHeader>
                <div className="d-flex justify-content-end">
                  <div>
                    <label>Start Date</label>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                  </div>
                  <div style={{ marginLeft: '-65px', marginRight: '-50px' }}>
                    <label>End Date</label>
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                  </div>
                  <div className="mt-4" style={{ marginLeft: '0px' }}>
                    <CButton
                      onClick={() => {
                        handelsubmit13()
                      }}
                      size="sm"
                      style={{ height: '30px', marginTop: '0px' }}
                    >
                      Submit
                    </CButton>
                  </div>
                </div>
              </CCardHeader>
              <CCardBody>
                {loading11 ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '80vh' }}
                  >
                    <div className="spinner-border m-5" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <CChartLine
                    className="singupchart"
                    data={{
                      labels: label02,
                      datasets: [
                        {
                          label: 'TurnOff',
                          backgroundColor: '#8884d8',
                          borderColor: '#8884d8',
                          pointBackgroundColor: '#8884d8',
                          pointBorderColor: '3px solid #8884d8',
                          data: turnOff,
                        },
                        {
                          label: 'Open',
                          backgroundColor: '#82ca9d',
                          borderColor: '#82ca9d',
                          pointBackgroundColor: '#82ca9d',
                          pointBorderColor: '3px solid #82ca9d',
                          data: open,
                        },
                        {
                          label: 'Total',
                          backgroundColor: '#292D30',
                          borderColor: '#292D30',
                          pointBackgroundColor: '#292D30',
                          pointBorderColor: '3px solid #292D30',
                          data: total,
                        },
                      ],
                    }}
                  />
                )}
              </CCardBody>
            </CCard>
          </div>
          <div>
            <CCard className=" m-4">
              <CCardHeader> Total Daily Reminder</CCardHeader>
              <CCardHeader>
                <div className="d-flex justify-content-end">
                  <div>
                    <label>Start Date</label>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                  </div>
                  <div style={{ marginLeft: '-65px', marginRight: '-50px' }}>
                    <label>End Date</label>
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                  </div>
                  <div className="mt-4" style={{ marginLeft: '0px' }}>
                    <CButton
                      onClick={() => {
                        handelsubmit14()
                      }}
                      size="sm"
                      style={{ height: '30px', marginTop: '0px' }}
                    >
                      Submit
                    </CButton>
                  </div>
                </div>
              </CCardHeader>
              <CCardBody>
                {loading12 ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '80vh' }}
                  >
                    <div className="spinner-border m-5" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <CChartLine
                    className="singupchart"
                    data={{
                      labels: label03,
                      datasets: [
                        {
                          label: 'TurnOff',
                          backgroundColor: '#8884d8',
                          borderColor: '#8884d8',
                          pointBackgroundColor: '#8884d8',
                          pointBorderColor: '3px solid #8884d8',
                          data: totalDaily,
                        },
                      ],
                    }}
                  />
                )}
              </CCardBody>
            </CCard>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
